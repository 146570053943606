<template>
    <div class="layout-dashboard">
        <WidgetsSkeleton v-if="loading"/>
        <Button
            v-else
            label="Добавить отчет"
            icon="pi pi-plus"
            class="p-button-sm"
            @click="addNewBoard"/>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import router from '../router'
import BoardService from '../services/BoardService'

export default {
    setup () {
        const store = useStore()
        const boardService = new BoardService()
        const loading = computed(() => store.state.board.getBoardsLoading)
        const boards = computed(() => store.state.board.getBoards)

        const addNewBoard = () => boardService.modalToggle('create')

        watch(boards, (state, prevState) => {
            if (Array.isArray(state) && state.length) {
                router.push({
                    name: 'board',
                    params: {
                        boardId: state[0].id
                    }
                })
            }
        })

        return {
            loading,
            addNewBoard
        }
    }
}
</script>

<style lang="scss">
</style>
